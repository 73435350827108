import { inject, Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import {
  CasinoGamePlayMode,
  CasinoTagType,
  ICasinoGame,
  ICasinoGameLaunchDetails,
  ICasinoGamesAndTags,
  ICasinoGamesGroup,
  ICasinoSearchParams,
  ICasinoTag,
  IJackpot
} from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import { adaptBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.mappers';
import {
  IBpCasinoGame,
  IBpCasinoSearchParams as IBPCasinoGamesSearchParams,
  IBpCasinoLaunchDetails,
  IBpCasinoSearchParams,
  IBpCasinoTag,
  IBpGamesWithTags,
  IBpJackpotItem
} from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import {
  adjustParamsForBetplatform,
  mapCasinoGames,
  mapCasinoJackpots,
  transformCasinoTagToGroupItem,
  transformCasinoTagToListItem,
  transformPayloadToCasinoGamesAndTags,
  transformPayloadToCasinoTags
} from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';

import { BpCasinoApiService } from 'bp-angular-library';

import { CasinoAbstractService, UserAbstractService } from '../../env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { JackpotWinnerComponent } from '../../../../shared/components/casino/jackpot-winner/jackpot-winner.component';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoBetplatformService extends CasinoAbstractService {
  private bpCasinoApiService: BpCasinoApiService = inject(BpCasinoApiService);
  private translateService: TranslateService = inject(TranslateService);
  private authService: AuthenticationService = inject(AuthenticationService);

  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private modalController: ModalController = inject(ModalController);

  public jackpots$: BehaviorSubject<IJackpot[] | null> = new BehaviorSubject<IJackpot[] | null>(null);

  constructor() {
    super();
    this.getJackpotsList();
  }

  public async getCasinoTypesOfTags(): Promise<IApiPayload<CasinoTagType[]>> {
    // TODO: Revisit return types here. The "CasinoTagType" is not the same as "CasinoTagTypes" from the API
    return new Promise<IApiPayload<CasinoTagType[]>>(async (resolve, reject) => {
      try {
        const response: IBpPayload<CasinoTagType[]> | null = await this.bpCasinoApiService.getCasinoTypesOfTags();
        const tmpResponse: IApiPayload<CasinoTagType[]> = {
          data: response?.data || [],
          total: response?.total,
          offset: response?.offset,
          limit: response?.limit
        };
        return resolve(tmpResponse);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino tag types'));
      }
    });
  }

  public async getCasinoTags(params: IBpCasinoSearchParams): Promise<IApiPayload<Partial<ICasinoTag>[]>> {
    return new Promise<IApiPayload<Partial<ICasinoTag>[]>>(async (resolve, reject) => {
      try {
        const response: IBpPayload<IBpCasinoTag[]> | null = await this.bpCasinoApiService.getCasinoTags(params);
        return resolve(adaptBpPayload(response, transformPayloadToCasinoTags(response?.data)));
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino tags'));
      }
    });
  }

  public async getAllCasinoGroups(params?: ICasinoSearchParams): Promise<IApiPayload<Partial<ICasinoGamesGroup<number>>[]>> {
    const tmpParams: IBpCasinoSearchParams = adjustParamsForBetplatform({ ...params, tagType: 'group' });

    return new Promise<IApiPayload<Partial<ICasinoGamesGroup<number>>[]>>(async (resolve, reject) => {
      try {
        const response: IApiPayload<Partial<ICasinoTag>[]> = await this.getCasinoTags(tmpParams);
        response.data = transformCasinoTagToGroupItem(response?.data);
        return resolve(response);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino games categories'));
      }
    });
  }

  public async getSubcategoriesByCategoryId(categoryId: string): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    const tmpParams: IBpCasinoSearchParams = adjustParamsForBetplatform({ tagIds: [categoryId], tagType: 'sub-category' });

    return new Promise<IApiPayload<Partial<IListItem<number>>[]>>(async (resolve, reject) => {
      try {
        const response: IApiPayload<Partial<ICasinoTag>[]> = await this.getCasinoTags(tmpParams);
        response.data = transformCasinoTagToListItem(response?.data);
        return resolve(response);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino game subcategories'));
      }
    });
  }

  public async getVendorsByCategoryIdAndSubCategoryId(categoryId: string, subCategoryId: string): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    const tmpParams: IBpCasinoSearchParams = adjustParamsForBetplatform({ tagIds: [categoryId, subCategoryId], tagType: 'vendor' });

    return new Promise<IApiPayload<Partial<IListItem<number>>[]>>(async (resolve, reject) => {
      try {
        const response: IApiPayload<Partial<ICasinoTag>[]> = await this.getCasinoTags(tmpParams);
        response.data = transformCasinoTagToListItem(response?.data);
        return resolve(response);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino game subcategories'));
      }
    });
  }

  public async getCasinoGameVendors(params?: ICasinoSearchParams): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    const tmpParams: IBpCasinoSearchParams = adjustParamsForBetplatform({ ...params, tagType: 'vendor' });

    return new Promise<IApiPayload<Partial<IListItem<number>>[]>>(async (resolve, reject) => {
      try {
        const response: IApiPayload<Partial<ICasinoTag>[]> = await this.getCasinoTags(tmpParams);
        response.data = transformCasinoTagToListItem(response?.data);
        return resolve(response);
      } catch (error) {
        return reject(new Error('Failed to retreive the list of casino game vendors'));
      }
    });
  }

  public async getCasinoGames(params: ICasinoSearchParams): Promise<IApiPayload<ICasinoGamesAndTags>> {
    return new Promise<IApiPayload<ICasinoGamesAndTags>>(async (resolve, reject) => {
      try {
        const tmpParams: IBPCasinoGamesSearchParams = {
          offset: params?.offset,
          limit: params?.limit,
          query: params?.query,
          tag_title: params?.tagTitle,
          type: params?.tagType,
          tag_ids: Array.isArray(params?.tagIds) ? params.tagIds : undefined,
          vendor: params?.vendor
        };
        const response: IBpPayload<IBpGamesWithTags> | null = await this.bpCasinoApiService.getAllCasinoGames(tmpParams);
        return resolve(adaptBpPayload(response, transformPayloadToCasinoGamesAndTags(response?.data)));
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  public async getCasinoGameById(id: number): Promise<Partial<ICasinoGame>> {
    return new Promise<Partial<ICasinoGame>>(async (resolve, reject) => {
      try {
        const response: IBpPayload<IBpCasinoGame> | null = await this.bpCasinoApiService.getCasinoGameById(id);

        if (!response?.data?.id) {
          return reject(new Error('Failed to retreive the casino game. Data is missing'));
        }
        const mappedGame: Partial<ICasinoGame> = mapCasinoGames([response.data])[0];
        return resolve(mappedGame);
      } catch (error) {
        return reject(new Error('Failed to retreive the casino game. Call to backend failed'));
      }
    });
  }

  public async getDetailsToLaunchGame(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: IBpPayload<IBpCasinoLaunchDetails> | null = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId, mode);
        return resolve({ url: response?.data?.launchUrl, token: response?.data?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  // TODO: Remove this method and replace it with getDetailsToLaunchGame or vice versa
  // TODO: Check if there are some other api calls that are not used and remove them
  public async getSingleCasinoGameToPlay(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGame[]> {
    return new Promise<ICasinoGame[]>(async (resolve, reject) => {
      try {
        const response: any = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId, mode);
        return resolve(response);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame')));
      }
    });
  }

  public async getJackpotsListForPlayers(): Promise<IJackpot[] | null> {
    return new Promise<IJackpot[] | null>(async (resolve, reject) => {
      try {
        const jackpots: IBpPayload<IBpJackpotItem[]> | null = await this.bpCasinoApiService.getJackpotsListForPlayers();

        if (!jackpots?.data) {
          return resolve(null);
        }

        return resolve(mapCasinoJackpots(jackpots?.data));
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames')));
      }
    });
  }

  /////// JACKPOTS SERVICE

  public processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void {
    if (playerId === this.authService.user$.value?.id) {
      this.presentJackpotWinnerModal(jackpot);
      this.userAbstractService.updateUserWithProfileData();
    }
  }

  public async getJackpotsList(): Promise<void> {
    const tmpValue: IJackpot[] | null = await this.getJackpotsListForPlayers();
    this.jackpots$.next(tmpValue);
  }

  public updateJackpotValue(jackpot: IJackpot): void {
    const tmpJackpots: IJackpot[] = this.jackpots$.value || [];
    const index: number = tmpJackpots.findIndex(item => item.id === jackpot.id);

    if (index !== -1) {
      tmpJackpots[index] = { ...tmpJackpots[index], ...jackpot };
      this.jackpots$.next(tmpJackpots);
    }
  }

  // TODO: IMPORTANT: Move this to layout service or some other service
  private async presentJackpotWinnerModal(jackpotDetails: IJackpot): Promise<void> {
    const modal = await this.modalController.create({
      component: JackpotWinnerComponent,
      componentProps: {
        jackpotDetails
      },
      cssClass: 'full-screen-modal'
    });

    await modal.present();

    setTimeout(() => {
      modal.dismiss();
    }, 10000);
  }

  // FAVORITES
  public async getFavoriteGames(): Promise<number[]> {
    return new Promise<number[]>(async (resolve, reject) => {
      try {
        const response: IBpPayload<number[]> | null = await this.bpCasinoApiService.getFavoriteGames();
        resolve(response?.data || []);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveFavoriteGames')));
      }
    });
  }

  public async getIdsOfFavoriteGames(): Promise<number[]> {
    return new Promise<number[]>(async (resolve, reject) => {
      try {
        const response: IBpPayload<number[]> | null = await this.bpCasinoApiService.getIdsOfFavoriteGames();
        resolve(response?.data || []);
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRetreiveFavoriteGames')));
      }
    });
  }

  public async addGameToFavorites(gameId: number): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.bpCasinoApiService.addGameToFavorites(gameId);
        resolve();
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToAddGameToFavorites')));
      }
    });
  }

  public async removeGameFromFavorites(gameId: number): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.bpCasinoApiService.removeGameFromFavorites(gameId);
        resolve();
      } catch (error) {
        return reject(new Error(this.translateService.instant('notifications.failedToRemoveGameFromFavorites')));
      }
    });
  }
}
