import { Routes } from '@angular/router';

import { INavGroup } from 'bp-framework/dist/common/common.interface';

import { runGuardsInOrder } from 'bp-angular-library';

import { ROUTES_FOR_MY_PROFILE, ROUTES_FOR_RULES, ROUTES_FOR_SUPPORT, SITE_FOOTER_NAVIGATION } from './routes.utils';
import { guardUntilAuthenticated } from 'src/app/core/guards/authentication.guard';
import { ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';

export const BETPLATFORM_PROD_JUST_CASINO_ROUTES: Routes = [
  //#region Main
  {
    path: ROUTE_PATHS.signUp,
    loadComponent: () => import('../../../../pages/sign-up/sign-up.component').then(m => m.SignUpComponent)
  },
  {
    path: ROUTE_PATHS.casino,
    loadChildren: () => import('../../../../pages-casino/casino.routes').then(m => m.CASINO_ROUTES)
  },
  {
    path: ROUTE_PATHS.greekKeno,
    loadComponent: () => import('../../../../pages-keno/keno/keno.component').then(m => m.kenoComponent),
    canActivate: [runGuardsInOrder(guardUntilAuthenticated(undefined, true))]
  },
  //#endregion Main
  //#endregion Player
  //#region My Profile
  ...ROUTES_FOR_MY_PROFILE,
  //#endregion My Profile
  //#region Support
  ...ROUTES_FOR_SUPPORT,
  //#endregion Support
  //#region Rules
  ...ROUTES_FOR_RULES,
  //#endregion Rules
  {
    path: 'casino-game-iframe-error',
    redirectTo: ROUTE_PATHS.notFound,
    pathMatch: 'full'
  },
  {
    path: ROUTE_PATHS.notFound,
    loadComponent: () => import('../../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: '',
    redirectTo: ROUTE_PATHS.casino, // Default page
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: ROUTE_PATHS.notFound
  }
];

export const BETPLATFORM_PROD_JUST_CASINO_SIDEMENU: Partial<INavGroup>[] = [
  {
    path: `/${ROUTE_PATHS.casino}`,
    label: 'Casino',
    t9nKey: 'pages.casino.title',
    sortOrder: 3,
    icon: 'ph-club'
  }
];

export const BETPLATFORM_PROD_JUST_CASINO_SITE_FOOTER_NAVIGATION: Partial<INavGroup>[] = SITE_FOOTER_NAVIGATION;
