import { NgIf } from '@angular/common';
import { Component, DestroyRef, HostListener, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';

import { IonicModule, Platform } from '@ionic/angular';

import { PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';

import { UserAbstractService } from './core/env-specific/env-abstracts';

import { PlatformService } from './core/services/platform/platform.service';
import { VersionService } from './core/services/pwa/version.service';

import { CasinoProvidersComponent } from './shared/components/casino/casino-providers/casino-providers.component';
import { SidemenuMyProfileComponent } from './shared/components/layout/menu-my-profile/sidemenu-my-profile.component';
import { SidemenuPrimaryComponent } from './shared/components/layout/sidemenu-primary/sidemenu-primary.component';

import { IEnvApiBase, IEnvConfigPlayerFrontend } from './shared/models/configuration/configuration.interface';
import { debounceTime } from 'rxjs';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// register Swiper custom elements
register();

@Component({
  selector: 'ion-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, IonicModule, SidemenuPrimaryComponent, SidemenuMyProfileComponent, CasinoProvidersComponent]
})
export class AppComponent implements OnInit {
  private projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private titleService: Title = inject(Title);
  private versionService: VersionService = inject(VersionService);
  private platformService: PlatformService = inject(PlatformService);
  private userAbstractService: UserAbstractService = inject(UserAbstractService);

  public disableSidemenu!: boolean;
  public isDesktopResolution!: boolean;

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: any): void {
    // TODO: Check should we keep these event handlers inside the AppComponent or move them to some other service
    // The reason why we might need them is the fact that Reload/Refresh on the browser won't trigger ngOnDestroy
    // IMPORTANT: Figure out a mechanism to unsubscribe EVERYTHING when the user leaves the app or refreshes the page (sse, rxjs, etc.)
    // IMPORTANT: TODO: In the newer versions of Angular, HostListener might be deprecated? Check if there is a better way to handle these events
    // TODO: We might need to implement logic that will check if the user token has expired when the user minimize app on the phone and then open it again
    console.log('');
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any): void {
    console.log('');
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychangeHandler(event: any): void {
    // TODO: Check should we keep these event handlers inside the AppComponent or move them to some other service
    // TODO: Investigage is this the best way to handle visibility change events
    // TODO: The reason why we need this is the fact that user can minimize the app on the phone and then open it again. We need to check if the token has expired or not in that time frame
    // TODO: Check if we need to implement 'visibilitychange' event on other/indivitual pages to ensure that the data is up to date when the user opens the app again
    if (document?.hidden === false) {
      // Update user profile data when the user opens the app again
      this.userAbstractService.updateUserWithProfileData();
    }
  }

  constructor() {
    this.versionService.initialize();
    this.disableSidemenu = this.projectConfig?.features?.frontendMode === 'surfpoint';
    this.setTitle();
  }

  ngOnInit(): void {
    this.observePlatformWidth();
  }

  private observePlatformWidth(): void {
    this.platformService
      ?.subscribeOnResize()
      ?.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(200))
      .subscribe(() => {
        this.isDesktopResolution = this.platformService.isDesktopResolution();
      });
  }

  private setTitle(): void {
    // TODO: We need better way to define title for the app. The title might be dependant on the environment, provider, domain, etc.
    const backendProvider = this.projectConfig?.api?.provider; // '1x2team' | 'betplatform'
    const frontendMode = this.projectConfig?.features?.frontendMode; // 'default' | 'surfpoint' | 'justcasino'
    let pageTitle = '';

    if (backendProvider === '1x2team') {
      pageTitle = '1x2Team';
    } else if (backendProvider === 'betplatform') {
      pageTitle = frontendMode === 'justcasino' ? 'Gorilla Casinos' : frontendMode === 'surfpoint' ? 'Surfpoint' : 'TempApp';
    } else {
      pageTitle = 'WIP App';
    }

    this.titleService.setTitle(this.projectConfig?.projectName);
  }
}
