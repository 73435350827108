import { inject } from '@angular/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { CasinoTagType, ICasinoGamesGroup, ICasinoTag } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';

import { CasinoAbstractService } from '../core/env-specific/env-abstracts';

import { RouteManagementService } from '../core/services/route-management/route-management.service';

import { sortBySortOrder } from '../shared/models/common/common.utils';
import { QUERY_PARAMS_KEYS } from '../shared/models/routing/routing.const';
import { IQueryParamsMap } from '../shared/models/routing/routing.interface';

export const populateTagIds = (
  selectedCategory: Partial<IListItem<number>> | null,
  selectedSubCategory: Partial<IListItem<number>> | null,
  selectedVendor: Partial<IListItem<number>> | null
): string[] => {
  const tmpTagIds: string[] = [];

  if (selectedCategory?.value) {
    tmpTagIds.push(`${selectedCategory.value}`);
  }

  if (selectedSubCategory?.value) {
    tmpTagIds.push(`${selectedSubCategory.value}`);
  }

  if (selectedVendor?.value) {
    tmpTagIds.push(`${selectedVendor.value}`);
  }

  return tmpTagIds;
};

/**
 * Checks if the current URL has additional segments after "casino/games".
 * @returns {boolean} True if additional segments exist, false otherwise.
 */
export const hasAdditionalSegments = (basePath: string, url: string): boolean => {
  // Check if the URL starts with the base path and has additioal segments
  return url.startsWith(basePath) && url.length > basePath.length;
};

export const transformCasinoTagToListItem = (list: ICasinoTag[], type: CasinoTagType): Partial<IListItem<number>>[] => {
  return list
    ?.filter((item: ICasinoTag) => item?.type === type)
    ?.map((item: ICasinoTag) => {
      const tmpItem: Partial<IListItem<number>> = {
        label: item?.title,
        value: item?.id,
        imgSrc: item?.imageUrl
      };
      return tmpItem;
    });
};

export const isCurrentGroupActuallyAllGames = (group: Partial<ICasinoGamesGroup<number>> | null): boolean => {
  return group?.label?.toLowerCase() === 'all';
};

export const returnIndexOfInitialItem = (allItems: Partial<IListItem<number>>[], queryParams: IQueryParamsMap, targetParamKey: string, defaultIndex: number): number => {
  const paramGroupId: unknown = queryParams[targetParamKey];
  const paramAsNumber: number = typeof paramGroupId === 'string' ? Number(paramGroupId) : -9999;
  const tmpIndex = allItems.findIndex((item: Partial<IListItem<number>>) => item?.value === paramAsNumber);
  return tmpIndex >= 0 ? tmpIndex : defaultIndex;
};
