import { inject, Injectable } from '@angular/core';

import { IJackpot } from 'bp-framework/dist/casino/casino.interface';
import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import { IEnvApiOnBetPlatform } from 'bp-framework/dist/env-specific/betplatform/configuration/configuration.interface';
import { IEventSubscriptionsPayload } from 'bp-framework/dist/env-specific/betplatform/subscriptions/subscriptions.interface';

import { BpCoreApiService, PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';

import { BackendEventsAbstractService, CasinoAbstractService } from '../../env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { IEnvConfig } from 'src/app/shared/models/configuration/configuration.interface';

export type SseEventTypes = 'Jackpot awarded' | 'Jackpot value updated' | 'New withdrawal' | 'New deposit';

export interface SingleProcessModel {
  date: Date;
  description: string;
  status: string;
}

export interface ISseData<T> {
  message: string; // "User ninoplayer deposited 5.00 eur \nType: admin_cash_deposit \nTheir new balance is: 161.46 eur",
  payload: T;
}

export interface ISseDepositOrWithdrawal {
  amount: number; // 5
  asset_type: string; // "eur"
  new_balance: number; // 161.46
  user_id: string; // "1557cb45-eba7-4437-a7c8-043eea7b4a7b"
  username: string; // "ninoplayer"
  method: string; // "admin_cash_deposit" | "admin_cash_withdrawal"
  created_at: string; // ""
}

export interface ISseJackpotAwarded {
  jackpot_id: number; // 1
  jackpot_name: string; // "Naš Jackpot"
  jackpot_round_id: number; // 11
  new_value: number; // 1000
  player_id: string; // "1557cb45-eba7-4437-a7c8-043eea7b4a7b"
  player_username: string; // "ninoplayer"
}

export interface ISseJackpotValueUpdate {
  jackpot_id: number; // 2
  jackpot_name: string; // "Naš Veći Jackpot"
  jackpot_round_id: number; // 12
  new_value: number; // 5000.164000000001
}

// https://stackoverflow.com/a/62057926
// https://www.aklivity.io/post/a-primer-on-server-sent-events-sse
// https://medium.com/@andrewkoliaka/implementing-server-sent-events-in-angular-a5e40617cb78
// https://sii.pl/blog/en/server-side-events-implementation-and-highlights/
// https://stackoverflow.com/a/77276727
// https://dev.to/miketalbot/server-sent-events-are-still-not-production-ready-after-a-decade-a-lesson-for-me-a-warning-for-you-2gie
// https://stackoverflow.com/questions/24564030/is-an-eventsource-sse-supposed-to-try-to-reconnect-indefinitely
// https://stackoverflow.com/a/61420107

@Injectable({
  providedIn: 'root'
})
export class BackendEventsBetplatformService extends BackendEventsAbstractService {
  private projectConfig: IEnvConfig<IEnvApiOnBetPlatform> = inject<IEnvConfig<IEnvApiOnBetPlatform>>(PROJECT_ENV_CONFIG_TOKEN);

  private bpCoreApiService: BpCoreApiService = inject(BpCoreApiService);
  private authService: AuthenticationService = inject(AuthenticationService);
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  private eventSource!: EventSource | null;

  private baseUrl = this.projectConfig?.api?.baseUrls?.default;
  private bpEventsSubscriptionsPath = `${this.baseUrl}/events/subscriptions`;
  private sseChannelUrl!: string;
  private SSE_EVENTS: SseEventTypes[] = ['Jackpot awarded', 'Jackpot value updated', 'New withdrawal', 'New deposit'];
  private reconnectFrequencySec = 1;
  private reconnectTimeout!: any;
  private SSE_RECONNECT_UPPER_LIMIT = 64;

  public async subscribeToBackendEvents(): Promise<void> {
    const response: IBpPayload<IEventSubscriptionsPayload> | null = await this.bpCoreApiService.setupEventSubscriptions();
    if (response?.data?.id) {
      this.sseChannelUrl = `${this.bpEventsSubscriptionsPath}/${response.data.id}`;
      this.createSseEventSource();
    }
  }

  /**
   * Method for creation of the EventSource instance
   */
  getEventSource(url: string): EventSource {
    return new EventSource(url, { withCredentials: false });
  }

  // Creates SSE event source, handles SSE events
  protected createSseEventSource(): void {
    // Close event source if current instance of SSE service has some
    if (this.eventSource) {
      this.closeSseConnection();
    }

    // Open new channel, create new EventSource
    this.eventSource = this.getEventSource(this.sseChannelUrl);

    // Process default event
    // this.eventSource.onmessage = (event: MessageEvent) => {
    // TODO: Check with Milos why our MessageEvent doesn't have the 'type' property and what is the purpose of the 'topics' from the IEventSubscriptionsPayload.payload
    //   this.zone.run(() => this.processSseEvent(event));
    // };

    // Add custom events
    this.SSE_EVENTS.forEach((type: SseEventTypes) => {
      if (!this.eventSource) {
        return;
      }

      this.eventSource.addEventListener(type, (event: MessageEvent) => {
        this.processSseEvent(type, event);
      });
    });

    // Process connection opened
    this.eventSource.onopen = (event: Event) => {
      this.reconnectFrequencySec = 1;
    };

    // Process error
    this.eventSource.onerror = (error: Event) => {
      // event.target is type of EventSource
      // console.log((event.target as EventSource).url);

      this.reconnectOnError();
    };
  }

  // Processes custom event types
  private processSseEvent(eventType: SseEventTypes, sseEvent: MessageEvent): void {
    const parsedData: ISseData<any> = sseEvent?.data ? JSON.parse(sseEvent.data) : {};

    switch (eventType) {
      case 'New deposit':
      case 'New withdrawal':
        this.authService.userDetailsChanged({
          account: {
            cash: {
              // TODO: Check if this approach is viable. Do we need to perform some check before updating user details in this way
              unit: parsedData?.payload?.asset_type,
              value: parsedData?.payload?.new_balance
            }
          }
        } as any);
        break;
      case 'Jackpot value updated':
        this.casinoAbstractService.updateJackpotValue({ id: parsedData?.payload?.jackpot_id, value: parsedData?.payload?.new_value } as IJackpot);
        break;
      case 'Jackpot awarded':
        this.casinoAbstractService.processJackpotAwardedEvent(parsedData?.payload?.player_id, {
          id: parsedData?.payload?.jackpot_id,
          value: parsedData?.payload?.new_value
        } as IJackpot);
        break;
      default:
        // TODO: Do we want to handle this kind of events? Log or ignore?
        console.error('Unknown SSE type:', sseEvent.type);
        break;
    }
  }

  // Handles reconnect attempts when the connection fails for some reason.
  private reconnectOnError(): void {
    this.closeSseConnection();

    clearTimeout(this.reconnectTimeout);

    this.reconnectTimeout = setTimeout(() => {
      this.createSseEventSource();
      this.reconnectFrequencySec *= 2;

      if (this.reconnectFrequencySec >= this.SSE_RECONNECT_UPPER_LIMIT) {
        this.reconnectFrequencySec = this.SSE_RECONNECT_UPPER_LIMIT;
      }
    }, this.reconnectFrequencySec * 1000);
  }

  /**
   * Method for closing the connection
   */
  closeSseConnection(): void {
    if (!this.eventSource) {
      return;
    }

    this.eventSource.close();
    this.eventSource = null;
  }
}
