import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { ICasinoGame, ICasinoGamesAndTags, ICasinoGamesGroup, ICasinoSearchParams, ICasinoTag } from 'bp-framework/dist/casino/casino.interface';
import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { IListItem } from 'bp-framework/dist/common/common.interface';

import { CasinoAbstractService } from '../core/env-specific/env-abstracts';

import { AuthenticationService } from '../core/services/auth/authentication.service';
import { RouteManagementService } from '../core/services/route-management/route-management.service';

import { sortBySortOrder } from '../shared/models/common/common.utils';
import { IPaginationInfo } from '../shared/models/pagination/pagination.interface';
import { getPaginationInfo } from '../shared/models/pagination/pagination.utils';
import { QUERY_PARAMS_KEYS } from '../shared/models/routing/routing.const';
import { IQueryParamsMap } from '../shared/models/routing/routing.interface';
import { isCurrentGroupActuallyAllGames, populateTagIds, returnIndexOfInitialItem, transformCasinoTagToListItem } from './casino.utils';
import { BehaviorSubject, combineLatest, debounceTime, filter, firstValueFrom, from, Observable, pairwise, switchMap, tap } from 'rxjs';

const SEARCH_LIMIT_INCREMENT = 50;
const INITIAL_SEARCH_OFFSET = 0;
const DEFAULT_INDEX_FOR_SELECTED_GROUP = 0;
const DEFAULT_INDEX_FOR_SELECTED_SUB_CATEGORY = -999;
const DEFAULT_INDEX_FOR_SELECTED_PROVIDER = -999;

type CombinedFilterParams = [Partial<ICasinoGamesGroup<number>> | null, Partial<IListItem<number>> | null, Partial<IListItem<number>> | null];

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  private routeManagementService: RouteManagementService = inject(RouteManagementService);
  private router: Router = inject(Router);
  private authService: AuthenticationService = inject(AuthenticationService);

  //#region Loaded Casino Games
  public allCasinoGames$: BehaviorSubject<Partial<ICasinoGame>[]> = new BehaviorSubject<Partial<ICasinoGame>[]>([]);
  public selectedGame$: BehaviorSubject<Partial<ICasinoGame> | null> = new BehaviorSubject<Partial<ICasinoGame> | null>(null);
  //#region
  //#region Casino Groups
  public selectedGroup$: BehaviorSubject<Partial<ICasinoGamesGroup<number>> | null> = new BehaviorSubject<Partial<ICasinoGamesGroup<number>> | null>(null);
  public allCasinoGroups$: BehaviorSubject<Partial<ICasinoGamesGroup<number>>[]> = new BehaviorSubject<Partial<ICasinoGamesGroup<number>>[]>([]);
  //#endregion
  //#region Casino Sub Categories
  public selectedSubCategory$: BehaviorSubject<Partial<IListItem<number>> | null> = new BehaviorSubject<Partial<IListItem<number>> | null>(null);
  public allSubCategoriesForCurrentSearch$: BehaviorSubject<Partial<IListItem<number>>[]> = new BehaviorSubject<Partial<IListItem<number>>[]>([]);
  //#endregion
  //#region Casino Providers
  public selectedProvider$: BehaviorSubject<Partial<IListItem<number>> | null> = new BehaviorSubject<Partial<IListItem<number>> | null>(null);
  public allProvidersUnderGroup$: BehaviorSubject<Partial<IListItem<number>>[]> = new BehaviorSubject<Partial<IListItem<number>>[]>([]);
  //#endregion
  //#region Search Keyword
  public searchKeyword$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  //#endregion
  //#region Favorite Games
  public playerFavoriteGamesList$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  //#endregion

  //#region Utils
  public showSkeletonLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loadingMoreGamesInProgress = false;
  public showLoadMoreButton = true;
  public paginationInfo!: IPaginationInfo;
  //#endregion

  constructor() {
    this.initialize();
  }

  private async initialize(): Promise<void> {
    await firstValueFrom(this.router.events.pipe(filter(e => e instanceof NavigationEnd || e instanceof NavigationCancel)));
    this.subscribeToChangesOnFilterParams();
    this.updateTheListOfFavoriteGamesWithBackendData();

    try {
      // Load Query Params
      const tmpQueryParams: IQueryParamsMap = this.routeManagementService.getCurrentQueryParams();
      // Load Casino Groups
      const tmpAllGroupsPayload: IApiPayload<Partial<ICasinoGamesGroup<number>>[]> = await this.casinoAbstractService.getAllCasinoGroups();
      const sortedGroups: Partial<ICasinoGamesGroup<number>>[] = sortBySortOrder(tmpAllGroupsPayload?.data || []);
      const initialItemIndex: number = returnIndexOfInitialItem(sortedGroups, tmpQueryParams, QUERY_PARAMS_KEYS.casinoGroupId, DEFAULT_INDEX_FOR_SELECTED_GROUP);
      const tmpGroup: Partial<ICasinoGamesGroup<number>> = sortedGroups[initialItemIndex];
      // Load Sub Categories
      const tmpSubCategoriesPayload: IApiPayload<Partial<IListItem<number>>[]> = await this.casinoAbstractService.getSubcategoriesByCategoryId(`${tmpGroup?.value}`);
      const sortedSubCategories: Partial<IListItem<number>>[] = sortBySortOrder(tmpSubCategoriesPayload?.data || []);
      const initialSubCategoryIndex: number = returnIndexOfInitialItem(
        sortedSubCategories,
        tmpQueryParams,
        QUERY_PARAMS_KEYS.casinoSubCategoryId,
        DEFAULT_INDEX_FOR_SELECTED_SUB_CATEGORY
      );
      const tmpSubCategory: Partial<IListItem<number>> = sortedSubCategories[initialSubCategoryIndex];
      // Load Providers
      const tmpProvidersPayload: IApiPayload<Partial<IListItem<number>>[]> = await this.casinoAbstractService.getVendorsByCategoryIdAndSubCategoryId(
        `${tmpGroup?.value}`,
        `${tmpSubCategoriesPayload?.data?.[0]?.value}`
      );
      const sortedProviders: Partial<IListItem<number>>[] = sortBySortOrder(tmpProvidersPayload?.data || []);
      const initialProviderIndex: number = returnIndexOfInitialItem(sortedProviders, tmpQueryParams, QUERY_PARAMS_KEYS.vendorId, DEFAULT_INDEX_FOR_SELECTED_PROVIDER);
      const tmpProvider: Partial<IListItem<number>> = sortedProviders[initialProviderIndex];
      // Populate the data
      this.allCasinoGroups$.next(sortedGroups);
      this.selectedGroup$.next(tmpGroup);
      this.allSubCategoriesForCurrentSearch$.next(sortedSubCategories);
      this.selectedSubCategory$.next(tmpSubCategory);
      this.allProvidersUnderGroup$.next(sortedProviders);
      this.selectedProvider$.next(tmpProvider);
    } catch (error) {
      console.error('Error while loading initial content', error);
    }
  }

  public observeChangesOnFilterParams(): Observable<CombinedFilterParams> {
    return combineLatest([this.selectedGroup$, this.selectedSubCategory$, this.selectedProvider$]);
  }

  private subscribeToChangesOnFilterParams(): void {
    this.observeChangesOnFilterParams()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        pairwise(),
        debounceTime(500),
        tap(() => {
          // TODO: IMPORTANT: Check if we need to add pipe for CatchError
          this.showSkeletonLoader$.next(true);
        }),
        switchMap(([prev, current]: [CombinedFilterParams, CombinedFilterParams]) => {
          console.log('prev', prev);
          console.log('current', current);
          const [currentGroup, currentSubCategory, currentProvider] = current;
          // TODO: IMPORTANT: This is a temporary solution for ALL. We should later revisit this with Lazar and Milos
          const tmpCategory: Partial<ICasinoGamesGroup<number>> | null = isCurrentGroupActuallyAllGames(currentGroup) ? null : currentGroup;
          const tmpSubCategory: Partial<IListItem<number>> | null = currentSubCategory?.value ? currentSubCategory : null;
          const tmpProvider: Partial<IListItem<number>> | null = currentProvider?.value ? currentProvider : null;

          this.updateQueryParamsWithCurrentFilters(currentGroup?.value, tmpSubCategory?.value, tmpProvider?.value);

          return from(this.fetchCasinoGamesByFilterParams(INITIAL_SEARCH_OFFSET, SEARCH_LIMIT_INCREMENT, [tmpCategory, tmpSubCategory, tmpProvider]));
        })
      )
      .subscribe((payload: IApiPayload<ICasinoGamesAndTags>) => {
        this.checkIfTheListOfProvidersShouldBeUpdated(payload?.data?.tags || []);
        this.checkIfTheListOfSubCategoriesShouldBeUpdated(payload?.data?.tags || []);
        // IMPORTANT: IF any of the 'casino game search' params are changed, we can consider that as a new search and we can reset the selected game
        this.performActionsAfterFetchingCasinoGames(payload, []);
        this.showSkeletonLoader$.next(false);
      });
  }

  private updateQueryParamsWithCurrentFilters(groupId?: number, subCategoryId?: number, providerId?: number): void {
    const tmpParams: IQueryParamsMap = {
      [QUERY_PARAMS_KEYS.casinoGroupId]: groupId,
      [QUERY_PARAMS_KEYS.casinoSubCategoryId]: subCategoryId,
      [QUERY_PARAMS_KEYS.vendorId]: providerId
    };
    this.routeManagementService.mergeQueryParams(tmpParams);
  }

  private performActionsAfterFetchingCasinoGames(newPayload: IApiPayload<ICasinoGamesAndTags>, prevListOfGames: Partial<ICasinoGame>[]): void {
    this.paginationInfo = getPaginationInfo(newPayload);
    this.showLoadMoreButton = !this.paginationInfo?.pagination?.isLastPage;
    this.allCasinoGames$.next([...prevListOfGames, ...(newPayload?.data?.games || [])]);
  }

  private checkIfTheListOfProvidersShouldBeUpdated(tags: ICasinoTag[]): void {
    if (this.allProvidersUnderGroup$.value?.length <= 0) {
      this.allProvidersUnderGroup$.next(transformCasinoTagToListItem(tags, 'vendor'));
    } else if (!this.selectedProvider$.value && !this.selectedSubCategory$.value) {
      this.allProvidersUnderGroup$.next(transformCasinoTagToListItem(tags, 'vendor'));
    }
  }

  private checkIfTheListOfSubCategoriesShouldBeUpdated(tags: ICasinoTag[]): void {
    if (!this.selectedSubCategory$.value?.value) {
      this.allSubCategoriesForCurrentSearch$.next(transformCasinoTagToListItem(tags, 'sub-category'));
    }
  }

  public async handleLoadMoreGames(): Promise<void> {
    this.loadingMoreGamesInProgress = true;

    try {
      const tmpPayload: IApiPayload<ICasinoGamesAndTags> = await this.fetchCasinoGamesByFilterParams(this.paginationInfo.nextPageOffset, SEARCH_LIMIT_INCREMENT, [
        this.selectedGroup$.value,
        this.selectedSubCategory$.value,
        this.selectedProvider$.value
      ]);

      this.performActionsAfterFetchingCasinoGames(tmpPayload, this.allCasinoGames$.value);
    } catch (error) {
      console.log('handleLoadMoreGames error', error);
    }

    setTimeout(() => {
      this.loadingMoreGamesInProgress = false;
    }, 500);
  }

  private async fetchCasinoGamesByFilterParams(offset: number, limit: number, filters: CombinedFilterParams): Promise<IApiPayload<ICasinoGamesAndTags>> {
    const [currentGroup, currentSubCategory, currentProvider] = filters;

    const tmpParams: ICasinoSearchParams = {
      offset,
      limit,
      query: EMPTY_STRING, // IMPORTANT: For now, we are not using the keyword as part of the 'local' search. Search by keyword is performed when the user selectes global serch
      tagIds: populateTagIds(currentGroup, currentSubCategory, currentProvider)
    };

    return this.casinoAbstractService.getCasinoGames(tmpParams);
  }

  public async checkIfTheGameProperlyLoaded(gameId?: number): Promise<void> {
    if (!gameId || this.selectedGame$.value?.id === gameId) {
      return;
    }

    try {
      const tmpGame: Partial<ICasinoGame> | null = await this.casinoAbstractService.getCasinoGameById(gameId);
      this.selectedGame$.next(tmpGame);
    } catch (error) {
      console.log('Failed to properly load game by id', error);
    }
  }

  public async updateTheListOfFavoriteGamesWithBackendData(): Promise<void> {
    if (!this.authService.isLoggedIn$.value) {
      return;
    }

    try {
      const tmpList: number[] = await this.casinoAbstractService.getFavoriteGames();
      this.playerFavoriteGamesList$.next(tmpList);
    } catch (error) {
      console.error('getListOfFavoriteGames error', error);
    }
  }
}
