/**
 * Sorts a list of items by their 'sortOrder' property.
 * @param list The list of items to be sorted.
 * @returns The sorted list.
 */
export function sortBySortOrder<T extends { sortOrder?: number }>(list: T[]): T[] {
  return list.sort((a: T, b: T) => {
    if (a.sortOrder === undefined || b.sortOrder === undefined) {
      return 99999;
    }
    return a.sortOrder - b.sortOrder;
  });
}
