import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { DialogsService } from 'bp-angular-library';

import { UserAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { SURFPOINT_ROUTE_PATHS } from '../../core/env-specific/betplatform/routes/routes.dev.surfpoint';

export const guardSurfpointNavigationIfPlayerBlocked = (): CanActivateFn => {
  return (): Promise<boolean | UrlTree> | boolean | UrlTree => {
    return new Promise<boolean | UrlTree>(async (resolve, reject) => {
      // Step 1. Inject dependencies
      const router = inject(Router);
      const authService: AuthenticationService = inject(AuthenticationService);
      const userAbstractService: UserAbstractService = inject(UserAbstractService);
      const dialogsService: DialogsService = inject(DialogsService);

      const user: Partial<IUserDetails> | null = authService.user$.value;

      if (!user?.id || !user?.auth?.token) {
        return resolve(router.parseUrl(`surfpoint/${SURFPOINT_ROUTE_PATHS.login}?autoLogin=false`));
      }

      try {
        const userDetails: Partial<IUserDetails> | null = await userAbstractService.getUserProfile();

        if (userDetails?.account?.isActive) {
          return resolve(true);
        } else {
          dialogsService.presentToast({ message: 'Player is blocked! Navigation is prevented.', color: 'danger', position: 'top', duration: 5000 });
          return resolve(router.parseUrl(`surfpoint/${SURFPOINT_ROUTE_PATHS.lobby}`));
        }
      } catch (error) {
        dialogsService.presentToast({ message: 'Error while fetching user profile! Navigation is prevented.', color: 'danger', position: 'top', duration: 5000 });
        // TODO: In here, we might want to redirect user to some other page depending on the page from which he tried to navigate
        return resolve(router.parseUrl(`surfpoint/${SURFPOINT_ROUTE_PATHS.lobby}`));
      }
    });
  };
};
